export default [
  // {
  //   title: 'Dashboards',
  //   icon: 'HomeIcon',
  //   // tag: '2',
  //   tagVariant: 'light-warning',
  //   children: [
  //     {
  //       title: 'Project Management',
  //       route: 'dashboard-ecommerce',
  //     },
  //     {
  //       title: 'Accounts',
  //       route: 'dashboard-analytics',
  //     },
  //   ],
  // },

  {
    title: 'Dashboard',
    icon: 'HomeIcon',
    route: 'RSPT-dashboard'
  },
]
