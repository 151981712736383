export default [
    {
        title: 'Client Management',
        icon: 'UsersIcon',
        route:'clientmanagement'
    },
    {
        title: 'Trainer Management',
        icon: 'TrelloIcon',
        route: 'trainermanagement'
    }, 
    {
        title: 'Workout Video Management',
        icon: 'FileIcon',
        route: 'workout-video-management'
    }, 
    {
        title: 'CMS',
        icon: 'CommandIcon',
        route: 'cms'
    },
     {
        title: 'Supplement List',
        icon: 'FileTextIcon',
        route: 'supplement'
    },                                                                                                                                          
    {
        title: 'Nutrition List',
        icon: 'GridIcon',
        route: 'nutrition'
    },
]
