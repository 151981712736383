<template>
  <!-- v-if="canViewHorizontalNavMenuHeaderLink(item)" -->
  <li class="nav-item" :class="{ 'sidebar-group-active active': isActive }">
    <b-link
      class="nav-link d-flex align-items-center"
      :to="{ name: item.route }"
    >
    <feather-icon  size="14" :icon="item.icon" />
    <span>{{ item.title }}</span>
  </b-link>
  <!-- <svg
  v-if="item.title === 'CMS'"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="#fffff"
    xmlns="http://www.w3.org/2000/svg"
    class="feather feather-file"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  >
    <path
      d="M4.58333 6.41666C5.59585 6.41666 6.41666 5.59585 6.41666 4.58333C6.41666 3.57081 5.59585 2.75 4.58333 2.75C3.57081 2.75 2.75 3.57081 2.75 4.58333C2.75 5.59585 3.57081 6.41666 4.58333 6.41666Z"
      stroke="#4B465C"
      stroke-width="1.75"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.58333 6.41666C5.59585 6.41666 6.41666 5.59585 6.41666 4.58333C6.41666 3.57081 5.59585 2.75 4.58333 2.75C3.57081 2.75 2.75 3.57081 2.75 4.58333C2.75 5.59585 3.57081 6.41666 4.58333 6.41666Z"
      stroke="white"
      stroke-opacity="0.2"
      stroke-width="1.75"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17.4168 6.41666C18.4293 6.41666 19.2502 5.59585 19.2502 4.58333C19.2502 3.57081 18.4293 2.75 17.4168 2.75C16.4043 2.75 15.5835 3.57081 15.5835 4.58333C15.5835 5.59585 16.4043 6.41666 17.4168 6.41666Z"
      stroke="#4B465C"
      stroke-width="1.75"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17.4168 6.41666C18.4293 6.41666 19.2502 5.59585 19.2502 4.58333C19.2502 3.57081 18.4293 2.75 17.4168 2.75C16.4043 2.75 15.5835 3.57081 15.5835 4.58333C15.5835 5.59585 16.4043 6.41666 17.4168 6.41666Z"
      stroke="white"
      stroke-opacity="0.2"
      stroke-width="1.75"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.58333 19.2506C5.59585 19.2506 6.41666 18.4298 6.41666 17.4173C6.41666 16.4048 5.59585 15.584 4.58333 15.584C3.57081 15.584 2.75 16.4048 2.75 17.4173C2.75 18.4298 3.57081 19.2506 4.58333 19.2506Z"
      stroke="#4B465C"
      stroke-width="1.75"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.58333 19.2506C5.59585 19.2506 6.41666 18.4298 6.41666 17.4173C6.41666 16.4048 5.59585 15.584 4.58333 15.584C3.57081 15.584 2.75 16.4048 2.75 17.4173C2.75 18.4298 3.57081 19.2506 4.58333 19.2506Z"
      stroke="white"
      stroke-opacity="0.2"
      stroke-width="1.75"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17.4168 19.2506C18.4293 19.2506 19.2502 18.4298 19.2502 17.4173C19.2502 16.4048 18.4293 15.584 17.4168 15.584C16.4043 15.584 15.5835 16.4048 15.5835 17.4173C15.5835 18.4298 16.4043 19.2506 17.4168 19.2506Z"
      stroke="#4B465C"
      stroke-width="1.75"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17.4168 19.2506C18.4293 19.2506 19.2502 18.4298 19.2502 17.4173C19.2502 16.4048 18.4293 15.584 17.4168 15.584C16.4043 15.584 15.5835 16.4048 15.5835 17.4173C15.5835 18.4298 16.4043 19.2506 17.4168 19.2506Z"
      stroke="white"
      stroke-opacity="0.2"
      stroke-width="1.75"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.5835 6.41602V15.5827"
      stroke="#4B465C"
      stroke-width="1.75"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.5835 6.41602V15.5827"
      stroke="white"
      stroke-opacity="0.2"
      stroke-width="1.75"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.4165 4.58337H15.5832"
      stroke="#4B465C"
      stroke-width="1.75"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.4165 4.58337H15.5832"
      stroke="white"
      stroke-opacity="0.2"
      stroke-width="1.75"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.4165 17.4174H15.5832"
      stroke="#4B465C"
      stroke-width="1.75"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.4165 17.4174H15.5832"
      stroke="white"
      stroke-opacity="0.2"
      stroke-width="1.75"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17.4165 6.41602V15.5827"
      stroke="#4B465C"
      stroke-width="1.75"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17.4165 6.41602V15.5827"
      stroke="white"
      stroke-opacity="0.2"
      stroke-width="1.75"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.1665 9.16736H12.8332"
      stroke="#4B465C"
      stroke-width="1.75"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.1665 9.16736H12.8332"
      stroke="white"
      stroke-opacity="0.2"
      stroke-width="1.75"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11 12.8327V9.16602"
      stroke="#4B465C"
      stroke-width="1.75"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11 12.8327V9.16602"
      stroke="white"
      stroke-opacity="0.2"
      stroke-width="1.75"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg> -->
<!-- <div v-else-if="item.icon === 'TrelloIcon'">
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="2.75"
      y="3.66663"
      width="16.5"
      height="14.6667"
      rx="3"
      stroke="#4B465C"
      stroke-width="1.75"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <rect
      x="2.75"
      y="3.66663"
      width="16.5"
      height="14.6667"
      rx="3"
      stroke="white"
      stroke-opacity="0.2"
      stroke-width="1.75"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <circle
      cx="8.24984"
      cy="9.16671"
      r="1.83333"
      stroke="#4B465C"
      stroke-width="1.75"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <circle
      cx="8.24984"
      cy="9.16671"
      r="1.83333"
      stroke="white"
      stroke-opacity="0.2"
      stroke-width="1.75"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.75 7.33333H15.5833"
      stroke="#4B465C"
      stroke-width="1.75"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.75 7.33333H15.5833"
      stroke="white"
      stroke-opacity="0.2"
      stroke-width="1.75"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.75 11H15.5833"
      stroke="#4B465C"
      stroke-width="1.75"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.75 11H15.5833"
      stroke="white"
      stroke-opacity="0.2"
      stroke-width="1.75"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.4165 14.6667H15.5832"
      stroke="#4B465C"
      stroke-width="1.75"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.4165 14.6667H15.5832"
      stroke="white"
      stroke-opacity="0.2"
      stroke-width="1.75"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</div> -->
<!-- <div v-else-if="item.icon === 'GridIcon'">
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.4165 7.33329H15.5832M6.4165 11H15.5832M6.4165 14.6666H11.9165M3.6665 3.66663H18.3332V18.3333H3.6665V3.66663Z"
      stroke="#4B465C"
      stroke-width="1.75"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.4165 7.33329H15.5832M6.4165 11H15.5832M6.4165 14.6666H11.9165M3.6665 3.66663H18.3332V18.3333H3.6665V3.66663Z"
      stroke="white"
      stroke-opacity="0.2"
      stroke-width="1.75"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</div> -->
  </li>
</template>

<script>
import { BLink } from "bootstrap-vue";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import { useUtils as useAclUtils } from "@core/libs/acl";
import useHorizontalNavMenuHeaderLink from "./useHorizontalNavMenuHeaderLink";
import mixinHorizontalNavMenuHeaderLink from "./mixinHorizontalNavMenuHeaderLink";

export default {
  components: {
    BLink,
  },
  mixins: [mixinHorizontalNavMenuHeaderLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { isActive, updateIsActive } = useHorizontalNavMenuHeaderLink(
      props.item
    );

    const { t } = useI18nUtils();
    const { canViewHorizontalNavMenuHeaderLink } = useAclUtils();

    return {
      isActive,
      updateIsActive,

      // ACL
      canViewHorizontalNavMenuHeaderLink,

      // i18n
      t,
    };
  },
};
</script>
