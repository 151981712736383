<template>
  <b-nav-item-dropdown right toggle-class="d-flex align-items-center dropdown-user-link" class="dropdown-user">
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ (userData?userData.fullName:'') || userData?userData.username:'' }}
        </p>
        <!-- <span class="user-status">{{ userData.role }}</span> -->
      </div>
      <b-avatar size="40" :src="userData ? userData.image : ''" variant="light-primary" badge class="badge-minimal"
        badge-variant="success">
      </b-avatar>
    </template>


    <div v-if="isSideDataLoading" style="height:50px" class="d-flex justify-content-center align-items-center">
      <b-spinner label="Loading..."></b-spinner>
    </div>
<!-- :to="{ path: 'admin/profile' }" -->
    <b-dropdown-item v-else link-class="d-flex align-items-center"
      class="align-items-center w-100">
      <div class="w-100">
        <div class="d-flex w-100 align-items-center">
          <div class="mr-1">
            <b-avatar size="35" :src="userData ? userData.image : ''" :text="userData?userData.f_name:''">
            </b-avatar>
          </div>
          <div class="m-0">
            <p class="m-0 font-weight-bolder">{{ userData ? userData.f_name : '' }}</p>
            <small>Admin</small>
          </div>
        </div>
      </div>
    </b-dropdown-item>
    <b-dropdown-item :to="{ name: 'profile-account-setting' }" link-class="d-flex align-items-center">
      <feather-icon size="16" icon="UserIcon" class="mr-50" />
      <span>My Profile</span>
    </b-dropdown-item>
    <!-- <b-dropdown-item
      :to="{ name: 'apps-email' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="MailIcon"
        class="mr-50"
      />
      <span>Inbox</span>
    </b-dropdown-item> -->
    <b-dropdown-item :to="{ name: 'password-account-setting' }" link-class="d-flex align-items-center">
      <feather-icon size="20" icon="LockIcon" class="mr-50" />
      <span style="font-size:12px;">Change Password</span>
    </b-dropdown-item>
    <b-dropdown-item :to="{ name: 'account-setting' }" link-class="d-flex align-items-center">
      <feather-icon size="20" icon="SettingsIcon" class="mr-50" />
      <span style="font-size:13px;">Setting</span>
    </b-dropdown-item>
    <!-- <b-dropdown-item :to="{ name: 'pages-help' }" link-class="d-flex align-items-center">
      <feather-icon size="16" icon="LifeBuoyIcon" class="mr-50" />
      <span>Help</span>
    </b-dropdown-item> -->

    <b-dropdown-divider />

    <b-dropdown-item :to="{ name: 'pages-faq' }" link-class="d-flex align-items-center">
      <feather-icon size="16" icon="AlertCircleIcon" class="mr-50" />
      <span>FAQ</span>
    </b-dropdown-item>
    <!-- <b-dropdown-item
      :to="{ name: 'pages-pricing' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="CreditCardIcon"
        class="mr-50"
      />
      <span>Pricing</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'pages-faq' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="HelpCircleIcon"
        class="mr-50"
      />
      <span>FAQ</span>
    </b-dropdown-item> -->
    <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
      <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
      <span>Sign Out</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar, BSpinner
} from 'bootstrap-vue'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
import { avatarText } from '@core/utils/filter'
import axios from '@/component/axios';

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BSpinner,
    BDropdownDivider,
    BAvatar,
  },
  data() {
    return {
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      userData: JSON.parse(localStorage.getItem('userData')),
      avatarText,
      isSideDataLoading: false,
      data: [],
    }
  },
  mounted() {
    // this.getProfile();
  },
  methods: {
    // async getProfile() {
    //   this.isSideDataLoading = true
    //   await axios({
    //     method: "GET",
    //     url: `${this.baseApi}auth/admin/get-profile/${this.userData.id}`,
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${this.accessToken}`,
    //     },
    //   })
    //     .then((res) => {
    //       this.data = res?.data.data
    //     })
    //     .catch((error) => console.log(error, "error"))
    //     .finally(() => {
    //       this.isSideDataLoading = false
    //     })
    // },
    async logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      // localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      // localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.clear()

      // Reset ability
      // this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    },
  },
}
</script>
